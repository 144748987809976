import React from "react";
import Content from "./Content";
import Cart from "./Cart"
import About from "./About";
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoIcon from '@mui/icons-material/Info';

import "./css/Main.css"

const StyledEnoughtBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: "#a70d0dff"
    },
}));

const StyledNotEnoughtBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: "green"
    },
}))

const MainPage = () => {

    const [cart, setCart] = React.useState<any[][]>([])
    const [showCart, setShowCart] = React.useState<boolean>(false)
    const [showAbout, setShowAbout] = React.useState<boolean>(false)
    const [blur, setBlur] = React.useState<boolean>(false)
    const [itemShow, setItemShow] = React.useState<boolean>(false)

    const handleShowCart = () => {
        console.log("showCart")
        if(itemShow === true || showAbout === true){
            setShowAbout(false)
            setItemShow(false)
            setBlur(true)
            setShowCart(true)
        }
        else {
            setBlur((prev) => !prev)
            setShowCart((prev) => !prev)
        }
    }

    const handleShowAbout = () => {
        console.log("about")
        if(itemShow === true || showCart === true){
            setShowCart(false)
            setItemShow(false)
            setBlur(true)
            setShowAbout(true)
        } else {
            setBlur(true)
            setShowAbout(true)
        }
        
    }

    React.useEffect(() => {
        // console.log(cart, "cart")
    }, [cart])

    return (
        <div className="main-page-frame">
            <header className="main-page-header-frame">
                <div className="main-page-header-info-frame">
                    <IconButton
                        aria-label="about"
                        size="large"
                        onClick={handleShowAbout}
                    >
                        <InfoIcon
                            // color="secondary"
                        >
                        </InfoIcon>
                    </IconButton>
                </div>
                <div className="main-page-header-label">
                    MIA GALLERY
                </div>
                <div className="main-page-header-cart-frame">
                {cart.length === 0 ? (
                    <IconButton 
                        aria-label="cart"
                        size="large"
                        onClick={handleShowCart}
                    >
                        <StyledEnoughtBadge 
                            badgeContent={cart.length} 
                            color="secondary"
                            showZero
                        >
                            <ShoppingCartIcon />
                        </StyledEnoughtBadge>
                    </IconButton>
                ) : (
                    <IconButton 
                        aria-label="cart"
                        size="large"
                        onClick={handleShowCart}
                    >
                        <StyledNotEnoughtBadge 
                            badgeContent={cart.length} 
                            color="secondary"
                            showZero
                        >
                            <ShoppingCartIcon />
                        </StyledNotEnoughtBadge>
                    </IconButton>
                )}
                </div>
            </header>
            <main className="main-page-main">
              <Content 
                itemShow={itemShow}
                onSetItemShow={setItemShow}
                blur={blur}
                onSetBlur={setBlur}
                onSetCart={setCart}
              />
            </main>
            <footer className="main-page-footer">

            </footer>
            {showCart && (
                    <Cart 
                        cart={cart}
                        onSetCart={setCart}
                        onSetBlur={setBlur}
                    />
            )}
            {showAbout && (
                <About
                    onSetShowAbout={setShowAbout}
                    onSetBlur={setBlur}
                />
            )}
        </div>
    )
}

export default MainPage