import React from "react";
import {MdOutlineClose} from "react-icons/md"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button, { ButtonProps } from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';


import { grey } from '@mui/material/colors'

import "./css/ItemInfo.css"

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

interface ItemInfoProps {
    data : any
    onSetItemShow : (show: boolean) => void
    onSetBlur : (blur: boolean) => void
    onSetCart: (cart : any) => void
}

const ItemInfo = (props: ItemInfoProps) => {
    const {data, onSetItemShow, onSetBlur, onSetCart} = props
    const [cost, setCost] = React.useState<number>(0)
    const [size, setSize] = React.useState<string>("")
    const [openAlert, setOpenAlert] = React.useState<boolean>(false)
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = data[0].length;

    React.useEffect(() => {
        // console.log(data[0], 14)
    }, [data])

    const closeItemInfo = () => {
        onSetItemShow(false)
        onSetBlur(false)
    }

    const chooseSize = ( event : any) => {
        // console.log( event.target.value )
        setSize(event.target.value)
        if( event.target.value === "20x30") setCost(3500)
        if( event.target.value === "30x40") setCost(5000)
        if( event.target.value === "60x90") setCost(11000)
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpenAlert(false);
      };

    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: theme.palette.getContrastText(grey[100]),
        // backgroundColor: grey[50],
        '&:hover': {
          backgroundColor: grey[100],
        },
      }));
    
      const handleCreateRequest = () => {
        if (cost === 0) {setOpenAlert(true)}
        if (cost !== 0) {
            onSetCart((cart: any) => [...cart, 
                {
                    "id": size + "_" + data[1] + "_" + Math.floor(Math.random() * 10000),
                    "size":size,
                    "cost":cost,
                    "image":data[0][0],
                    "label":data[1]
                }
            ])
            onSetItemShow(false)
            onSetBlur(false)
        }
      }
    
    return (
        <div className="item-info-frame">
            <div className="item-info-top">
                <div className="item-info-close-button-frame">
                    <IconButton 
                        aria-label="delete"
                        size="large"
                        onClick={closeItemInfo}
                    >
                        <ClearIcon />
                    </IconButton>
                    {/* <MdOutlineClose className="item-info-close-button" onClick={closeItemInfo}/> */}
                </div>
            </div>
            <div className="item-info-midle">
                <div className="item-info-midle-block-left">
                    <div className="item-info-midle-block-left-img-frame">
                    <Box sx={{ maxWidth: "100%", flexGrow: 1 }}>
                        <AutoPlaySwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                        >
                            {data[0].map((el: any, index: number) => (
                            <div key={el}>
                                {Math.abs(activeStep - index) <= 2 ? (
                                <Box
                                    component="img"
                                    sx={{
                                    height: 340,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    // maxWidth: 400,
                                    overflow: 'hidden',
                                    width: '100%',
                                    objectFit: "contain"
                                    }}
                                    src={el}
                                    // alt={step.label}
                                />
                                ) : null}
                                </div>
                            ))}
                        </AutoPlaySwipeableViews>
                        <MobileStepper
                            steps={maxSteps}
                            position="static"
                            activeStep={activeStep}
                            nextButton={
                            <ColorButton
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                            >
                                
                                {theme.direction === 'rtl' ? (
                                <KeyboardArrowLeft />
                                ) : (
                                <KeyboardArrowRight />
                                )}
                            </ColorButton>
                            }
                            backButton={
                            <ColorButton 
                                size="small" 
                                onClick={handleBack} 
                                disabled={activeStep === 0}
                            >
                                {theme.direction === 'rtl' ? (
                                <KeyboardArrowRight />
                                ) : (
                                <KeyboardArrowLeft />
                                )}
                             
                            </ColorButton>
                            }
                        />
                        </Box>
                        {/* <img className="item-info-midle-block-left-img" src={data[0]} alt="" /> */}
                    </div>
                </div>
                <div className="item-info-midle-block-right">
                    <div className="item-info-midle-block-right-title-frame">
                        <div className="item-info-midle-block-right-title">
                            {data[1]}
                        </div>
                    </div>
                    <div className="item-info-midle-block-right-text-frame">
                        <p className="item-info-midle-block-right-text">
                        Фотоснимок печатают на прочном полотне из хлопковых нитей и натягивают на деревянный подрамник. 
                        Натяжка обычная - 2 сантиметра. Торцы белые. Цвета фото не блекнут и остаются насыщенными при 
                        помощи современной технологии ультрафиолетовой печати. Вместе с картиной в упаковку идет специальный
                        крепёж для комфортного закрепления картины на стене.  
                        </p>
                    </div>
                    <div className="item-info-midle-block-right-choose-size-frame">
                        <div className="item-info-midle-block-right-choose-size">
                        <FormControl>
                            <p className="item-info-midle-block-right-choose-size-label">Размер :</p>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                <FormControlLabel value="20x30" control={<Radio color="default"/>} label="20x30" onClick={chooseSize}/>
                                <FormControlLabel value="30x40" control={<Radio color="default"/>} label="30x40" onClick={chooseSize}/>
                                <FormControlLabel value="60x90" control={<Radio color="default"/>} label="60x90" onClick={chooseSize}/>
                                
                            </RadioGroup>
                        </FormControl>
                        <div className="item-info-midle-block-right-cost-frame">
                            <div className="item-info-midle-block-right-cost">
                                {cost}
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="item-info-info-midle-block-right-button-add-frame">
                        <div className="item-info-info-midle-block-right-button-add" onClick={handleCreateRequest}>
                            Добавить в корзину
                        </div>
                    </div>
                </div>
            </div>
            <div className="item-info-bottom">
            </div>
            <Snackbar 
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                open={openAlert} 
                autoHideDuration={6000} 
                onClose={handleClose}
            >
                <Alert 
                    onClose={handleClose} 
                    severity="error" 
                    sx={{ width: '100%' }}
                >
                    Что бы добавить в корзину, необходимо выбрать размер.
                </Alert>
            </Snackbar>
        </div>
    )
}

export default ItemInfo