import React from "react";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import TelegramIcon from '@mui/icons-material/Telegram';

import "./css/About.css"

interface AboutProps {
    onSetShowAbout: (about: boolean) => void
    onSetBlur: (blur: boolean) => void
}

const About = (props: AboutProps) => {
    const {onSetShowAbout, onSetBlur} = props

    const handleCloseAbout = () => {
            onSetShowAbout(false)
            onSetBlur(false)
    }

    return(
        <div className="about-frame">
            <div className="about-body">
                <div className="about-body-header">
                    <div className="about-body-heder-close">
                    <IconButton 
                        aria-label="delete"
                        size="large"
                        onClick={handleCloseAbout}
                    >
                        <ClearIcon />
                    </IconButton>
                    </div>
                </div>
                <div className="about-body-main">
                <p>
                    Всем привет! Меня зовут Илья. Я являюсь автором представленных на данном сайте фотографий. Все фотографии сделаны на Зенит 12СД + Гелиос. В своих работах я хочу передать красоту окружающего нас с вами мира, заключенную в архитектуре, живой природе и не только. Если вам понравилась какая-то из моих работ, то у вас есть возможность заказать фотографию в виде фотокартины, которая станет прекрасным дополнением вашего интерьера. Если у вас есть какие то вопросы, то вы можете связаться со мной.
                </p>
                </div>
                <div className="about-body-footer">
                    <div className="about-body-footer-contacts">
                        <div className="about-body-footer-contacts-ico-frame">
                            <TelegramIcon></TelegramIcon>
                        </div>
                        <div className="about-body-footer-contacts-account-frame">
                            ilyamk
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About