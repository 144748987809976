import { createSlice } from '@reduxjs/toolkit'

export const cartItems = createSlice({
  name: 'cartItems',
  initialState: {
    value: []
  },
  reducers: {
    increment: (state, action) => {
      state.value = action.payload
    },
    decrement: state => {
      state.value = []
    },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // }
  }
})

// Action creators are generated for each case reducer function
export const { increment, decrement } = cartItems.actions

export default cartItems.reducer