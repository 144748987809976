import React from "react";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { Outlet, Link, redirect } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { increment, decrement } from "./store/cartItems";


import "./css/Cart.css"

interface CartProps {
    cart : any [][]
    onSetCart: (cart: any) => void
    onSetBlur: (blur: boolean) => void
}

const Cart = (props: CartProps) => {
    const store = useSelector( (state: any) => state.cartItems.value)
    const dispatch = useDispatch()

    const {cart, onSetCart, onSetBlur} = props
    const [totalCost, setTotalCost] = React.useState<number>(0)

    React.useEffect(() => {
        if(store.length > 0){
            // console.log(store.length, store)

            // onSetCart( (prevState: any) => {
            //     return store.map( (image: any) => image)
            // })
        }
        if( cart.length > 0 ){
            const costs = cart.map( (image: any) => image.cost)
            setTotalCost(costs.reduce( (sum, element) => { return sum + element}, 0))

            // console.log(totalCost)
        }
    }, [cart])

    const handleDeleteFromCart = (id: string) => {
        onSetCart( (cart: any) => {
            const newCart = cart.filter((image: any) => image.id !== id)
            return newCart
        })
    }

    const makeOrder = () => {
        dispatch(increment(cart))
        // redirect("/order")
    }

    return (
        <div className="cart-frame">
            <div className="cart-body">
                <div className="cart-label">
                    <div className="cart-name">
                            Корзина
                    </div>
                    <div className="cart-length">
                            {cart.length}
                            {cart.length === 0 ? 
                                " картин" :
                                cart.length === 1 ?
                                " картина" :
                                cart.length > 0 && cart.length < 5 ?
                                " картины" : " картин"
                            }
                            
                    </div>
                </div>
                <div className="cart-items-frame">
                    {cart.length === 0 ? (
                        <div className="cart-not-enough">
                            Корзина пуста
                        </div>
                    ) : (
                        cart.map( (image : any, index: number) => 
                            <div className="cart-item" key={index}>
                                <div className="cart-image-frame">
                                    <img src={image.image} alt="" className="cart-image" />
                                </div>
                                <div className="cart-item-info-frame">
                                    <div className="cart-item-label">
                                        {image.label}
                                    </div>
                                    <div className="cart-item-info">
                                        <div className="cart-item-info-size">
                                            {image.size} cm
                                        </div>
                                        <div className="cart-item-info-cost">
                                            {image.cost}
                                        </div>
                                    </div>
                                </div>
                                <div className="cart-item-delete">
                                    <IconButton 
                                        aria-label="delete"
                                        size="large"
                                        onClick={() => handleDeleteFromCart(image.id)}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                </div>
                            </div>
                        )
                    )}
                </div>
                {cart.length > 0 && (
                        <div className="cart-make-order-frame">
                            <div className="cart-make-order" onClick={makeOrder}>
                            <Link to={"/order"}>
                                Оформить заказ на {totalCost}
                            </Link>
                            </div>
                        </div>
                )}
            </div>
        </div>
    )
}

export default Cart