import React, { useEffect } from "react";
import PhotoEquip from './PhotoEquip'
import ItemInfo from "./ItemInfo";
import './css/Content.css'

interface ContentProps {
    itemShow: boolean
    onSetItemShow : (show: boolean) => void
    blur: boolean
    onSetBlur : (blur: boolean) => void
    onSetCart: (cart : any) => void
}

const Content = (props: ContentProps) => {

    const {onSetCart, itemShow, onSetItemShow, blur, onSetBlur} = props

    // let blocksWithImages : any = React.useRef(0)
    let albumImages: any = React.useRef(null)
    let portraitImages : any = React.useRef(null)
    let allImages: any = React.useRef([])

    const [itemInfo, setItemInfo] = React.useState<string []>([])
    const [show, setShow] = React.useState<boolean>(false)

    useEffect(() => {
        // console.log(__dirname)
        fetch("http://www.mia-gallery.ru/images",{
            method : "GET",
            // mode: "no-cors",
            // headers: {
            //     "Access-Control-Allow-Origin": "*"
            // }
        })
        .then((response) => {
            // console.log(response.status)
            return response.json();
          })
        .then( data => {
            // console.log(data)
            let index = 0
            // blocksWithImages.current = data.images.album.length + data.images.portrait.length
            albumImages.current = data.images.album.map( (el : any, index: number) => {
                return  <div className="main-page-content-frame" key={'album' + index}>
                            <img className="image-album-orientation" onClick={() => showItemWindow(el)} src={el[0][0]} alt="" />
                        </div>
            })
            portraitImages.current = data.images.portrait.map( (el : any, index: number) => {
                return  <div className="main-page-content-frame"  key={'portrait' + index}>
                            <img className="image-portrait-orientation" onClick={() => showItemWindow(el[0])} src={el[0][0][0]} alt="" />
                            <img className="image-portrait-orientation" onClick={() => showItemWindow(el[1])} src={el[1][0][0]} alt="" />
                            <img className="image-portrait-orientation" onClick={() => showItemWindow(el[2])} src={el[2][0][0]} alt="" />
                        </div>
            })
            allImages.current = albumImages.current

            portraitImages.current.forEach( (el: any) => {
                index = index + 2
                allImages.current.splice(index, 0, el)
                index = index + 1
            })
            setShow(true)
            // console.log(data.images, data.images.album, Math.floor(data.images.portrait.length / 3), albumImages.current, 12, show, allImages)

        })
        .catch( err => console.log( err ))
        // document.getElementsByClassName("main-page-content")[0].addEventListener('click', () => console.log(albumImages))
    }, [show])

    const showItemWindow = (data: string[]) => {
        // console.log( data )
        setItemInfo(data)
        onSetItemShow(true)
        onSetBlur(true)
    }

    return (
        <div className="main-page-content">
            <div className="main-page-photo-equip-frame">
                {/* <PhotoEquip /> */}
            </div>
            {show && (
                allImages.current.map((el : any) => el) 
            )}
            {blur && (
                <div className="main-page-blur"></div>
            )}
            { itemShow && (
                <ItemInfo 
                    data={itemInfo}
                    onSetItemShow={onSetItemShow}
                    onSetBlur={onSetBlur}
                    onSetCart={onSetCart}
                />
            )}
        </div>
    )
}

export default Content