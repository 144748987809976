import React from "react";
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import * as Yup from 'yup'
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

import "./css/Order.css"
import { redirect, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from './store/cartItems'

interface OrderProps {}

const validation = Yup.object().shape({
    name: Yup.string().matches(/^\p{Script=Cyrillic}+$/u, "Некорректное имя"),
    secondName: Yup.string().matches(/^\p{Script=Cyrillic}+$/u, "Некорректная фамилия"),
    telephone: Yup.string().matches(/^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/, "Некорректный номер")
})

const AlertOrder = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const Order = (props: OrderProps) => {
    const store = useSelector( (state: any) => state.cartItems.value)
    const [makingOrder, setMakingOrder] = React.useState<boolean>(false)
    const navigate = useNavigate()
    const formik = useFormik({
             initialValues: {
               name: '',
               secondName: '',
               telephone: '',
               telegram: '',
               order: store
             },
             validationSchema: validation,
             onSubmit: values => {
            //    alert(JSON.stringify(values, null, 2));
               const message = {
                    chat_id: 338645232, // Telegram chat id
                    text: values // The text to send
                };
               fetch("https://api.telegram.org/bot6857872619:AAF3Chh-ACsprIsY1s09EvY2Z5fx-sRJZVc/sendMessage", {
                    method: "POST",
                    body: JSON.stringify(message),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                      },
               }).then((request) => {
                    if(!request.ok){
                        throw new Error('Error occurred!')
                    } else {
                        // console.log(request.status, store)
                        setMakingOrder(true)
                        // redirect("/")
                    }
               }).then(() => {
                    setTimeout(() => {
                        navigate("/")
                    }, 6000)
               }).catch( err => console.log(err))
             },
    });

    const handleCloseMakingOrder = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setMakingOrder(false);
      };

    return(
    <div className="order-frame"> 
        <header className="order-page-header-frame">
            <div className="order-page-header-info-frame">
            </div>
            <div className="order-page-header-label">
                MIA GALLERY
            </div>
            <div className="order-page-header-cart-frame">
            </div>
        </header>
        <main className="order-body">
            <div className="order-body-frame">
                <form id="make-order" className="order-form"  onSubmit={formik.handleSubmit}>
                    <div className="order-form-label">Оформление заказа</div>
                    <div className="order-form-body">
                        <div className="order-form-body-row">
                            {formik.errors.name ? 
                                <TextField
                                    required
                                    error
                                    id="name"
                                    label="Имя"
                                    helperText={formik.errors.name}
                                    margin="dense"
                                    // defaultValue={formik.initialValues.name}
                                    variant="standard"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                />
                            : 
                                <TextField
                                    required
                                    id="name"
                                    label="Имя"
                                    margin="dense"
                                    // defaultValue={formik.initialValues.name}
                                    variant="standard"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                />
                            }
                           
                             {formik.errors.secondName ? 
                                <TextField
                                    required
                                    error
                                    id="secondName"
                                    label="Фамилия"
                                    margin="dense"
                                    helperText={formik.errors.secondName}
                                    defaultValue={formik.initialValues.secondName}
                                    variant="standard"
                                    value={formik.values.secondName}
                                    onChange={formik.handleChange}
                                />
                            : 
                                <TextField
                                    required
                                    id="secondName"
                                    label="Фамилия"
                                    margin="dense"
                                    defaultValue={formik.initialValues.secondName}
                                    variant="standard"
                                    value={formik.values.secondName}
                                    onChange={formik.handleChange}
                                />
                            }
                            {formik.errors.telephone ? 
                                 <TextField
                                    required
                                    error
                                    id="telephone"
                                    label="Телефон"
                                    margin="dense"
                                    helperText={formik.errors.telephone}
                                    variant="standard"
                                    value={formik.values.telephone}
                                    onChange={formik.handleChange}
                                />
                            : 
                                <TextField
                                    required
                                    id="telephone"
                                    label="Телефон"
                                    margin="dense"
                                    defaultValue={formik.initialValues.telephone}
                                    variant="standard"
                                    value={formik.values.telephone}
                                    onChange={formik.handleChange}
                                />
                            }
                            <TextField
                                required
                                id="telegram"
                                label="Telegram"
                                margin="dense"
                                defaultValue={formik.initialValues.telegram}
                                variant="standard"
                                value={formik.values.telegram}
                                onChange={formik.handleChange}
                            />
                        </div>
                        {/* <div className="order-form-body-row"></div> */}
                    </div>
                    <div className="order-form-buttons">
                        <div className="order-form-submit-frame">
                            <button type="submit" className="order-form-submit">
                                Заказать
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
                <Snackbar 
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={makingOrder} 
                    autoHideDuration={6000} 
                    onClose={handleCloseMakingOrder}
                >
                    <AlertOrder 
                        onClose={handleCloseMakingOrder} 
                        severity="success" 
                        sx={{ width: '100%' }}
                    >
                        Ваш заказ создан! Наш менеджер в скором времени с вами свяжется.
                    </AlertOrder>
                </Snackbar>
        </main>
    </div>
    )
}

export default Order